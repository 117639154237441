/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

window.post = function(path, params, method = 'post') {

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;

            if(Array.isArray(params[key]) || params[key] instanceof Object) {
                hiddenField.value = JSON.stringify(params[key]);
            }
            else {
                hiddenField.value = params[key];
            }

            form.appendChild(hiddenField);
        }
    }

    const csrf = document.getElementById('csrf');
    form.appendChild(csrf);

    document.body.appendChild(form);
    form.submit();
}
